import localForage from 'localforage';
import _ from 'lodash'
import { postToServeServer } from './common';
/**
 * 创建权限代码分类
 */
export async function addPrivCat(obj) {
    return await postToServeServer('/priv/add-priv-cat', obj);
}
/**
 * 获取权限代码分类列表
 */
export async function getPrivCats(obj) {
    return await postToServeServer('/priv/get-priv-cats', obj);
}
/**
 * 修改权限代码分类
 * @param {*} obj
 */
export async function setPrivCat(obj){
    return await postToServeServer('/priv/set-priv-cat', obj);
}
/**
 * 删除权限代码分类
 * @param {*} obj
 */
export async function delPrivCat(obj){
    return await postToServeServer('/priv/del-priv-cat', obj);
}
/**
 * 创建权限代码
 * @param {*} obj
 */
export async function addPriv(obj){
    return await postToServeServer('/priv/add-priv', obj);
}
/**
 * 获取权限代码列列表
 * @param {*} obj
 */
export async function getPrivs(obj){
    return await postToServeServer('/priv/get-privs', obj);
}
/**
 * 修改权限代码
 * @param {*} obj
 */
export async function setPriv(obj){
    return await postToServeServer('/priv/set-priv', obj);
}
/**
 * 删除权限代码
 * @param {*} obj
 */
export async function delPriv(obj){
    return await postToServeServer('/priv/del-priv', obj);
}
/**
 * 获取可分配权限列列表
 * @param {*} obj
 */
export async function getCanAssignPrivs(obj){
    return await postToServeServer('/role/get-can-assign-privs', obj);
}
/**
 * 创建⻆角⾊色 
 * @param {*} obj
 */
export async function addRole(obj){
    return await postToServeServer('/role/add-role', obj);
}
/**
 * 获取⻆角⾊色列列表  
 * @param {*} obj
 */
export async function getRoles(obj){
    return await postToServeServer('/role/get-roles', obj);
}
/**
 * 修改⻆色信息 
 * @param {*} obj
 */
export async function setRole(obj){
    return await postToServeServer('/role/set-role', obj);
}
/**
 * 删除⻆⾊
 * @param {*} obj
 */
export async function delRol(obj){
    return await postToServeServer('/role/del-role', obj);
}
/**
 * 创建管理理员 
 * @param {*} obj
 */
export async function addUser(obj){
    return await postToServeServer('/user/add-user', obj);
}
/**
 * 获取管理理员列列表    
 * @param {*} obj
 */
export async function getUsers(obj){
    return await postToServeServer('/user/get-users', obj);
}
/**
 * 修改管理理员信息 
 * @param {*} obj
 */
export async function setUser(obj){
    return await postToServeServer('/user/set-user', obj);
}
/**
 * 修改管理理员密码 
 * @param {*} obj
 */
export async function setUserPasswd(obj){
    return await postToServeServer('/user/set-user-passwd', obj);
}
/**
 * 删除管理理员  
 * @param {*} obj
 */
export async function delUser(obj){
    return await postToServeServer('/user/del-user', obj);
}
/**
 * 创建导航菜单  
 * @param {*} obj
 */
export async function addNav(obj){
    return await postToServeServer('/nav/add-nav', obj);
}
/**
 * 获取菜单列列表   
 * @param {*} obj
 */
export async function getNavs(obj){
    return await postToServeServer('/nav/get-navs', obj);
}
/**
 * 修改导航菜单  
 * @param {*} obj
 */
export async function setNav(obj){
    return await postToServeServer('/nav/set-nav', obj);
}
/**
 * 删除导航菜单 
 * @param {*} obj
 */
export async function delNav(obj){
    return await postToServeServer('/nav/del-nav', obj);
}

