<template>
    <div style="height:100%">
        <div class="page-header">产品资料管理</div>
        <div class="flex" style="margin:20px;">
            <div style="margin-right:20px;">
                <el-input style="max-width:250px;" placeholder="搜索工厂" 
                prefix-icon="el-icon-search" v-model="inputValue"></el-input>
            </div>
            <div>
                <el-input style="max-width:250px;" placeholder="搜索产品" 
                prefix-icon="el-icon-search" v-model="inputValue"></el-input>
            </div>
            <div class="flex-1" style="text-align:right;">
                <el-button style="margin:4px 0;" @click="goto('/purview/submitPro')" size="small" type="primary">创建新单品</el-button>
            </div>
        </div>
        <div class="block" style="height: calc(100% - 167px)">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" style="width: 100%">
                    <el-table-column prop="id" label="商品编号">
                    </el-table-column>
                    <el-table-column prop="privs" label="工厂名字" min-width="150">
                        <template slot-scope="scope">
                            <div>{{scope.row.name}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="商品名称" min-width="105">
                        <template slot-scope="scope">
                            <div v-if="scope.row.person">{{scope.row.person}}</div>
                            <div v-else>无分配</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="图片" min-width="105">
                        <template slot-scope="scope">
                            <img :src="scope.row.imgUrl">
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="是否上架">
                        <template slot-scope="scope">
                            <div v-if="scope.row.putState == 0">否</div>
                            <div v-if="scope.row.putState == 1">是</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="可见范围" min-width="105">
                        <template slot-scope="scope">
                            <div>{{scope.row.time}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="套系" min-width="105">
                        <template slot-scope="scope">
                            <div>{{scope.row.taixi}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="类目" min-width="105">
                        <template slot-scope="scope">
                            <div>{{scope.row.leimu}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="状态" min-width="105">
                        <template slot-scope="scope">
                            <div v-if="scope.row.stat == 0">已审核</div>
                            <div v-if="scope.row.stat == 1">未审核</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" width="110">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="edit(scope.row)">
                                编辑
                            </el-button>
                            <el-button type="text" size="small" @click="edit(scope.row)">
                                审核
                            </el-button>
                            <el-button type="text" size="small" @click="del(scope.row)">
                                下架
                            </el-button>
                            <el-button type="text" size="small" @click="del(scope.row)">
                                删除
                            </el-button>
                            <el-button type="text" size="small" @click="goto('/purview/drawDesign')">
                                打样管理
                            </el-button>
                            <el-button type="text" size="small" @click="goto('/purview/submitFile')">
                                创建附件
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="padding:12px">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.pageNumber" :page-size="queryOpt.pageCount" :total="total"></el-pagination>
            </div>
        </div>

        <!-- <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <ItemPerson v-if="popTitle=='分配人员跟进'" :form="ruleForm" :popTitle="popTitle" @refreshItems="refreshItems" @hide="popVisible=false"></ItemPerson>
            <ItemJournal v-if="popTitle=='新增日志'" :form="ruleForm" :popTitle="popTitle" @refreshItems="refreshItems" @hide="popVisible=false"></ItemJournal>
        </el-dialog> -->
        
    </div>
</template>
<script>
    import { getRoles,delRol } from '@/service/purview';
    export default {
        components: {
            
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                queryOpt:{
                    pageNumber:1,
                    pageCount:15,
                },
                total:0,
                list:[],
                inputValue:"",
            }
        },
        mounted() {
            this.list.push({
                id:"111",
                name:"xxxxx家具品牌公司",
                person:"若风",
                imgUrl:require('../../../assets/images/user.png'),
                putState:1,
                stat:0,
                time:"2020/1/12",
                taixi:"套系1",
                leimu:"实木床",
            })
        },
        created() {
            this.refreshItems();
        },
        methods: {
            goto(link) {
                this.$router.push(link)
            },
            refreshItems() {
                console.log('refreshItems');
                getRoles().then(rst => {
                    // if (rst && rst.length>0) {
                    //     this.list = rst;
                    // }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            edit() {
                this.$router.push("/purview/submitPro")
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    /*----选项卡----*/
    .tab {
        padding:0 20px;
    }
    .tab_menu {
        /* background: #fff;
        padding: 0 20px;
        margin: 20px; */
    }
    .tab_menu .menu_item{
        display: inline-block;
        vertical-align: top;
        width: 80px;
        height: 70px;
        text-align: center;
        line-height: 70px;
        margin-right:60px;
        cursor: pointer;
        font-size: 14px;
        color: #808080;
    }
    .tab_menu .menu_item.cur{
        color: #4D4D4D;
        border-bottom: 2px solid #4D4D4D;
    }
</style>